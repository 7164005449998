<template>
  <div class="campus">
    <a-tabs ref="demo" class="campus-a-tabs" tab-position="top" size="small" type="card" :animated="true"
      :default-active-key="defKey">
      <a-tab-pane v-for="item in tabsList" :key="item.id" :tab="item.name + '(' + item.num + '人)'"
        class="campus-a-tab-pane">
        <h2 class="campus-list-h">
          <img src="../../assets/image/146.png" alt="" />岗位职责
        </h2>
        <ol class="ol-list">
          <li v-for="(list1, index1) in item.dutyItem" :key="index1">
            {{ list1 }}
          </li>
        </ol>
        <h2 class="campus-list-h">
          <img src="../../assets/image/147.png" alt="" />任职要求
        </h2>
        <ol class="ol-list">
          <li v-for="(list2, index2) in item.askItem" :key="index2">
            {{ list2 }}
          </li>
        </ol>
        <h4>{{ item.money }}</h4>
        <h4>{{ item.experience }}</h4>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // tabPosition:'left',
      tabsList: [
        {
          id: 1,
          name: "施工安全员",
          num: 1,
          dutyItem: [
            '1.建立电气自动化和弱电工程施工安全技术现场管理体系',
            '2.制定和监督落实施工高风险管理标准，如：登高，密闭空间，电气作业等工作管理标准',
            '3.工机具，特种设备安全管理规范制定和落实执行',
            '4.特种设备管理（特种设备年检、档案管理）',
            '5.特种设备作业人员培训及资质管理',
          ],
          askItem: [
            '1.安全/机械/电气/自动化等相关专业',
            '2.最少一年以上熟悉机电安装工程施工作业安全管理或工厂设备管理经验',
            '3.熟悉生产线作业岗位风险的辨识和制定对策措施',
            '4.熟悉机电、自动化原理及特种设备等相关国家技术标准',
            '5.具有设备保全工作经验者可优先选用',
            '6.取得安全员资格证书',
          ],
          money: '薪资：4-8k',
          experience: '工作经验：3-5年'
        },
        {
          id: 2,
          name: "项目资料员",
          num: 1,
          dutyItem: [
            '1、负责机电工程项目的所有图纸的接收、清点、登记、发放归档等管理工作',
            '2、收集整理过程中所有技术变更、洽商记录、会议纪要等资料并归挡',
            '3、负责工程项目备案资料的填写、会签、整理、报送和归档',
            '4、负责施工资料的编制管理，做到资料完整及时，与工程进度同步',
            '5、按时、按质向主管部门移交项目验收档案和资料',
            '6、指导工程技术人员妥善保管施工技术资料(包括设备进场、验收资料等)',
            '7、负责施工部位产值完成情况进行汇总申报，定期编制施工统计报表',
            '8、负责项目各类合同的挡案管理',
          ],
          askItem: [
            '1.机械/电气/自动化等相关专业',
            '2.大专以上学历，有1-3年工作经验，有一个完整项目经验'
          ],
          money: '薪资：4-8k',
          experience: '工作经验：3-5年'
        },

        {
          id: 4,
          name: '电气设计工程师',
          num: 2,
          dutyItem: [
            '1.配合自动化生产线等项目的研发设计，设计绘制电气原理图、接线图、电控柜以及布局图等',
            '2.电气部件选型和物料清单输出',
            '3.根据项目设备功能要求，完成PLC等控制软件的编程和调试',
            '4.与其他工程师沟通系统接口设计、安装位置和走线',
            '5.完成现场电气设备调试，分析处理所遇到的问题',
            '6.负责相关设备的售后服务工作',
            '7.统筹、并协调解决工程项目运作过程中的技术问题',
            '8.负责工程项目电气相关输出资料的编制、更改、整理更新、归档。并协助完成研发设计项目认证验收工作',
            '9.配合项目组完成项目总结改进',
            '10.配合市场部提供并完成项目前期的技术支持及方案撰写工作',
            '11.完成上级交予的其他工作'
          ],
          askItem: [
            '1.学历专业：本科及以上学历；电气工程、自动控制工程相关专业',
            '2.工作经验：3年以上电气自动控制相关设计与调试工作经验',
            '3.知识技能：精通、AB、或欧姆龙等PLC及触摸屏等的硬件选型、组态及软件编程；熟悉各类电机、气缸和传感器等部件的电气设计和调试；熟练使用AutoCAD、lectrical/EPlan等电气设计软件；熟悉自动测量控制原理；有非标自动化行业工作经验者优先；英文水平良好',
            '4.其他要求：为人正直、诚实；善于沟通、观察敏锐；工作认真负责，严谨细致，有良好的团队合作精神',
          ],
        },
        {
          id: 5,
          name: '调试工程师',
          num: 2,
          dutyItem: [
            '1.电气原理图、控制柜布置的设计、绘制，元器件选型询价、采购',
            '2.负责完成工程项目电气控制软件设计任务，包括PLC/HMI、SCADA软件等',
            '3.完成在公司内部所负责项目的PLC、人机界面软件的调试工作',
            '4.负责生产线项目实施，与相关人员协调，推进项目按时交付',
            '5.工程项目输出资料的编制、更改、整理更新、归档',
          ],
          askItem: [
            '1.全日制本科以上学历，自动化或电气工程相关专业，三年以上自动化行业产品电气设计开发经验',
            '2.有独立完成项目电气控制柜硬件设计、PLC软件设计、现场调试的工作经验',
            '3.精通AB、欧姆龙等主流PLC、人机界面的编程',
            '4.精通上位机软件FactoryTalkview，WinCC,Intouch等编程',
            '5.英语四级或以上水平，有机器人行业应用经验者优先',
            '6.良好的沟通能力、快速学习能力、组织协调能力',
          ]
        },
        {
          id: 6,
          name: 'BIM机电工程师',
          num: 2,
          dutyItem: [
            '1、负责创建机电专业BIM模型，基于CAD二维图纸创建三维模型、添加指定的BIM构件信息',
            '2、配合项目需求负责BIM可持续设计（碰撞检查、净高分析、净空优化、管线综合、模型深化等）',
            '3、负责项目BIM机电族库的构件模型建立，完善构件库的更新与维护',
            '4、负责对设计图纸进行深化校核并提出碰撞报告、机电综合优化报告',
          ],
          askItem: [
            '1、本科及以上学历',
            '2、熟悉机电各专业图纸，优先考虑具有BIM等级证书，BIM项目咨询、建模工作或管线综合工作经验者',
            '3、熟练使用Revit、Navisworks、Fuzor等相关软件（掌握多者优先）',
            '4、具有良好的沟通能力，团队合作精神'
          ]
        },
        {
          id: 7,
          name: 'web前端开发工程师',
          num: 1,
          dutyItem: [
            '1.根据业务需求文档完成前端研发工作；与后端研发人员配合，讨论交互方式，页面集成工作；完成现有系统的维护工作，完成相关的迭代优化工作',
            '2.根据系统平台的业务需求，完成前端架构，并编制相关的前端规范等技术文档,网页标准化及优化研究',
            '3.负责公司前端的测试计划和优化，编写设计文档和测试分析报告等文档，沉淀和积累前端的测试框架和发布规范及机制',
            '4.负责前端系统性能优化工作，确立优化方向及可执行和量化的优化方案',
            '5.完成领导交办的其它任务。使用Vue3和TypeScript设计、开发和维护高质量的Web应用程序'
          ],
          askItem: [
            '1.本科及以上学历，平面设计或视觉传达相关专业',
            '2.有较强的界面交互设计能力， 优秀的美术功底，能熟练使用 Axure、Mockplus、PS、AI等软件',
            '3.两年及以上产品界面的交互设计的工作经验（能力优秀者可放宽），需有实际案例或相关设计作品',
            '4.能熟练掌握各类用户体验研究方法及分析方法，对交互设计过程有深入了解，可以独立完成整个设计过程',
            '5.工作积极主动，承压能力强，思维活跃，勇于创新，具有良好的团队合作精神'
          ]
        },
      ],

      defKey: "",
    };
  },
  mounted() {
    this.defKey = "1";
    console.log(this.$refs.demo);
  },
};
</script>

<style lang="less">
.campus {
  .campus-list-h {
    font-size: 24px;
    font-family: SourceHanSansCN-Regular;
    line-height: 30px;
  }

  .campus-a-tabs {
    width: 1440px;
    margin: 60px auto;
  }

  .ol-list {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular;

    li {
      margin-top: 10px;
    }
  }
}

@media all and (max-width: 700px) {
  .campus {
    .campus-list-h {
      font-size: 48px;
      font-family: SourceHanSansCN-Regular;
      line-height: 52px;
    }

    .campus-a-tabs {
      width: 1440px;
      margin: 60px auto
    }

    .ol-list {
      font-size: 32px;
      font-family: SourceHanSansCN-Regular;

      li {
        margin-top: 10px;
        font-size: 48px;
      }
    }
  }
}
</style>
